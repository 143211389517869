<template>
	<div class=".gg-container-1">
	  <div class="search-container">
		<div class="search-container-fn-input">
		  <label>患者信息：</label>
		  <el-input
			style="width: 200px"
			size="small"
			placeholder="请输入患者姓名"
			prefix-icon="el-icon-search"
			v-model="searchParams.pat_name"
			clearable
			@change="getList"
		  >
		  </el-input>
		</div>
		<div class="search-container-fn-input">
		  <label>问诊编号：</label>
		  <el-input
			style="width: 200px"
			size="small"
			placeholder="请输入患者姓名"
			prefix-icon="el-icon-search"
			v-model="searchParams.order_sn"
			clearable
			@change="getList"
		  >
		  </el-input>
		</div>
		
			<el-button
				size="mini"
				type="primary"
				style="padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
				@click="getList"
			>
				<span>查找</span>
			</el-button>
		<div
		  style="margin-left: auto; display: flex; justify-content: space-around"
		>
		  <div class="search-container-fn-input" v-if="operateList.indexOf('mul_upload') > -1">
			<el-button
			  size="mini"
			  type="primary"
			  icon="el-icon-refresh"
			  style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										background-color: #2362FB; border-color: #2362FB"
			  @click="handlerBatchUpload"
			  v-if="multipleSelectionPres.length > 0"
			  >批量上传
			</el-button>
		  </div>
		  <div class="search-container-fn-input" v-if="operateList.indexOf('upload') > -1">
			<el-button
			  size="mini"
			  type="primary"
			  icon="el-icon-refresh"
			  style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										background-color: #2362FB; border-color: #2362FB"
			  @click="handleUpload(multipleSelectionPres[0])"
			  :disabled="multipleSelectionPres[0].supervise_status !== 1"
			  v-if="multipleSelectionPres.length == 1 "
			  >上传
			</el-button>
		  </div>
		  <div class="search-container-fn-input" v-if="operateList.indexOf('update_table') > -1">
			<el-button
			  size="mini"
			  type="primary"
			  icon="el-icon-edit-outline"
			  style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										  background-color: #2362FB; border-color: #2362FB"
			  @click="handleEditTableHead"
			  >编辑表头
			</el-button>
		  </div>
		  <div class="search-container-fn-input" v-if="operateList.indexOf('reset_query') > -1">
			<el-button
			  size="mini"
			  type="primary"
			  icon="el-icon-refresh"
			  style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										  background-color: #2362FB; border-color: #2362FB"
			  @click="handleResetSearch"
			  >重置查找
			</el-button>
		  </div>
		  <!--对应第三方图标的button样式-->
		</div>
	  </div>
	  <!----------------表格---------------->
	  <el-table
		border
		size="mini"
		v-loading="listLoading"
		:header-cell-style="{ 'text-align': 'center' }"
		:data="tableData"
		height="725"
		@selection-change="handleSelectionChange"
		style="width: 100%;z-index:0"
	  >
		<el-table-column type="selection" width="55" align="center" />
		<el-table-column type="index" align="center" width="50">
		</el-table-column>
		<template v-for="(column, index) in tableHead">
		  <el-table-column
			:prop="column.column_prop"
			:label="
			  column.column_label_user_definition
				? column.column_label_user_definition
				: column.column_label
			"
			:width="column.width ? column.width : ''"
			:key="index"
			align="center"
			v-if="column.field_type === 'textBtn' && column.visible === true"
			show-overflow-tooltip
		  >
			<template slot-scope="scope">
			  <a
				style="font-size: 12px; color: #2379fb"
				@click.prevent="
				  activeName = 'first';
				  getDetail(scope.row);
				"
			  >
				{{ scope.row[column.column_prop] }}
			  </a>
			</template>
		  </el-table-column>
		  <el-table-column
			:prop="column.column_prop"
			:label="
			  column.column_label_user_definition
				? column.column_label_user_definition
				: column.column_label
			"
			width="120px"
			:key="index"
			align="center"
			v-else-if="
			  column.field_type === 'imageBtn' &&
				column.visible === true &&
				column.column_label == '电子病历'
			"
			show-overflow-tooltip
		  >
			<template slot-scope="scope">
			  <div
				@mouseenter="enter(scope.$index)"
				@mouseleave="leave()"
				@click.stop="under(scope.row.emr_pic, 'pic' + scope.$index)"
				v-if="scope.row.emr_pic && scope.row.emr_pic.length > 0"
			  >
				<transition name="fade">
				  <div
					v-show="scope.$index === isPicIdx"
					style="position: absolute;background-color: rgba(101, 101, 101, .3); width: 108px; height: 108px; z-index: 3999"
				  ></div>
				</transition>
				<i
				  v-show="seen"
				  class="el-icon-zoom-in"
				  style="position: absolute;top: 50%;left: 50%;
								  transform: translate(-50%, -50%); font-size:26px; color: #fff; width: 30px; height: 30px; z-index: 4000"
				></i>
				<el-image
				  :ref="'pic' + scope.$index"
				  style="width: 100px; height: 100px;"
				  :z-index="zIndex"
				  :src="
					scope.row.emr_pic instanceof Array ? scope.row.emr_pic[0] : ''
				  "
				  :preview-src-list="
					scope.row.emr_pic instanceof Array ? scope.row.emr_pic : []
				  "
				>
				</el-image>
			  </div>
			  <div v-else>
				暂无
			  </div>
			</template>
		  </el-table-column>
		  <el-table-column
			:prop="column.column_prop"
			:label="
			  column.column_label_user_definition
				? column.column_label_user_definition
				: column.column_label
			"
			width="120px"
			:key="index"
			align="center"
			v-else-if="
			  column.field_type === 'imageBtn' &&
				column.visible === true &&
				column.column_label == '处方笺'
			"
			show-overflow-tooltip
		  >
			<template slot-scope="scope">
			  <div
				@mouseenter="enter2(scope.$index)"
				@mouseleave="leave2()"
				@click="under2(scope.row.pres_pic, 'pic2' + scope.$index)"
				v-if="scope.row.pres_pic && scope.row.pres_pic.length > 0"
			  >
				<transition name="fade">
				  <div
					v-if="scope.$index === isPicIdx2"
					style="position: absolute;background-color: rgba(101, 101, 101, .3); width: 108px; height: 108px; z-index: 3999"
				  ></div>
				</transition>
				<i
				  v-show="seen2"
				  class="el-icon-zoom-in"
				  style="position: absolute;top: 50%;left: 50%; transform: translate(-50%, -50%); font-size:26px; color: #fff; width: 30px; height: 30px; z-index: 4000"
				></i>
				<el-image
				  :ref="'pic2' + scope.$index"
				  style="width: 100px; height: 100px;"
				  :z-index="zIndex"
				  :src="
					scope.row.pres_pic instanceof Array
					  ? scope.row.pres_pic[0]
					  : ''
				  "
				  :preview-src-list="
					scope.row.pres_pic instanceof Array ? scope.row.pres_pic : []
				  "
				>
				</el-image>
			  </div>
			  <div v-else>
				暂无
			  </div>
			</template>
		  </el-table-column>
		  <el-table-column
			:prop="column.column_prop"
			:label="
			  column.column_label_user_definition
				? column.column_label_user_definition
				: column.column_label
			"
			:width="column.width ? column.width : ''"
			:key="index"
			align="center"
			v-else-if="column.field_type === 'select' && column.visible === true"
			show-overflow-tooltip
		  >
			<template slot-scope="scope">
			  <el-switch
				class="switch"
				@change="changeExamine(scope.row)"
				v-model="scope.row.status"
				:active-value="1"
				:inactive-value="0"
				active-text="启用"
				inactive-text="禁用"
				active-color="#13ce66"
				inactive-color="#C8C8C8"
			  >
			  </el-switch>
			</template>
		  </el-table-column>
		  <el-table-column
			:sortable="tableHeadSortable.includes(column.column_prop)"
			:prop="column.column_prop"
			:label="
			  column.column_label_user_definition
				? column.column_label_user_definition
				: column.column_label
			"
			:width="column.width ? column.width : ''"
			:key="index"
			align="center"
			v-else-if="column.visible === true"
			show-overflow-tooltip
		  >
			<template slot-scope="scope">
			  <a v-if="column.column_prop == 'type'">
				{{ scope.row.type == 1 ? "图文问诊" : "视频问诊" }}
			  </a>
			  <a v-else-if="column.column_prop == 'supervise_status'">
				{{ scope.row.supervise_status | formatSuperviseStatus }}</a
			  >
			  <a v-else-if="column.column_prop == 'from_source'">
				{{ from_sources[scope.row.from_source]}}</a
			  >
			  <div v-else-if="column.column_prop == 'video_file'">
				<div v-if="scope.row.video_file.length == 0">
				  暂无
				</div>
				<div v-else>
				  <a :href="scope.row.video_file[0]" style="color: blue"
					>患者视频</a
				  >
				  <br />
				  <a :href="scope.row.video_file[1]" style="color: blue"
					>医生视频</a
				  >
				</div>
			  </div>
  
			  <a v-else>{{ scope.row[column.column_prop] }}</a>
			</template>
			<template #header>
			  <el-popover
				placement="bottom"
				title=""
				min-width="160"
				trigger="click"
				v-if="
				  searchTableHead.filter(
					(item) => item.name == column.column_prop
				  ).length > 0
				"
			  >
				<span slot="reference" class="search-header">
				  <span class="search-title">{{
					column.column_label_user_definition
					  ? column.column_label_user_definition
					  : column.column_label
				  }}</span>
				  <i
					style="margin-left: 3px;font-weight: bolder; font-size: 20px"
					class="el-icon-search"
					:class="{
					  isSearch: searchTableHead.filter(
						(item) => item.name == column.column_prop
					  )[0].isSearch,
					  'el-icon-zoom-in': searchTableHead.filter(
						(item) => item.name == column.column_prop
					  )[0].isSearch,
					}"
				  />
				</span>
				<el-date-picker
				  v-if="'created_at' == column.column_prop"
				  v-model="searchParams.created_at"
				  type="daterange"
				  size="mini"
				  value-format="yyyy-MM-dd"
				  range-separator="至"
				  start-placeholder="开始日期"
				  end-placeholder="结束日期"
				  @change="getList"
				>
				</el-date-picker>
				<el-select
				  v-if="'supervise_status' == column.column_prop"
				  style="width: 200px"
				  @change="
					handleSearch(
					  column.column_prop,
					  searchParams.supervise_status
					)
				  "
				  v-model="searchParams.supervise_status"
				  clearable
				  placeholder="请选择"
				  size="small"
				>
				  <el-option
					v-for="item in superviseStatusList"
					:key="item.value"
					:label="item.label"
					:value="item.value"
				  >
				  </el-option>
				</el-select>
				<el-select
				  v-if="'type' == column.column_prop"
				  style="width: 200px"
				  @change="handleSearch(column.column_prop, searchParams.type)"
				  v-model="searchParams.type"
				  clearable
				  placeholder="请选择"
				  size="small"
				>
				  <el-option
					v-for="item in superviseType"
					:key="item.value"
					:label="item.label"
					:value="item.value"
				  >
				  </el-option>
				</el-select>
				<el-input
				  v-if="'doctors_name' == column.column_prop"
				  size="mini"
				  placeholder=""
				  prefix-icon="el-icon-search"
				  v-model="searchParams.doctors_name"
				  clearable
				  @change="
					handleSearch(column.column_prop, searchParams.doctors_name)
				  "
				>
				</el-input>
				<el-input
				  v-if="'order_sn' == column.column_prop"
				  size="mini"
				  placeholder=""
				  prefix-icon="el-icon-search"
				  v-model="searchParams.order_sn"
				  clearable
				  @change="
					handleSearch(column.column_prop, searchParams.order_sn)
				  "
				>
				</el-input>
			  </el-popover>
			  <span v-else>{{
				column.column_label_user_definition
				  ? column.column_label_user_definition
				  : column.column_label
			  }}</span>
			</template>
		  </el-table-column>
		</template>
	  </el-table>
  
	  <!----------------分页---------------->
	  <!--<pagination :total="total" :page.sync="listQuery.page" -->
	  <!--						:limit.sync="listQuery.limit"-->
	  <!--						@pagination="getList"/>-->
  
	  <pagination
		:total="total"
		:page.sync="listQuery.page"
		:limit.sync="listQuery.limit"
		@pagination="getList"
	  />
	  <!--编辑表头-->
	  <editTableHead
		:isActiveEditTableHead.sync="isActiveEditTableHead"
		@_getAdminFieldIndex="_getAdminFieldIndex"
		v-if="isActiveEditTableHead"
		@getList="getList"
    :table_type="table_type"
	  >
	  </editTableHead>
	  <!--drawer-->
	  <el-drawer
		v-loading="listLoading"
		:before-close="handleCloseDrawer"
		@closeDrawer="handleCloseDrawer"
		title=""
		size="76%"
		:visible.sync="drawerVisible"
		:with-header="false"
	  >
		<therapyDetail
		  @closeDrawer="closeDrawer"
		  :info="info"
		  :id="pres_id"
		  v-if="drawerVisible"
		  :diag_id="diag_id"
		>
		</therapyDetail>
	  </el-drawer>
	</div>
  </template>
  
  <script>
  import editTableHead from "@/components/editTableHead/editTableHead";
  import { getAdminFieldIndex } from "@/api/drugs2.0";
  import Pagination from "@/components/Pagination";
  import therapyDetail from "@/views/informationSearch/components/therapyDetail";
  import {
	getTherapy,
	getTherapyDetail,
	delTherapyById,
	putTherapyBatchSupervise,
  } from "@/api/informationSearch";
  import { mapState } from "vuex";
  
  export default {
	name: "diagSupervise",
	components: {
	  Pagination,
	  // eslint-disable-next-line vue/no-unused-components
	  therapyDetail,
	  editTableHead,
	},
	computed:{
		...mapState({
			table_options: (state) => state.user.table_options,
		})
	},
	async created() {
	  await this._getAdminFieldIndex();
	  this.getList();
	  const res = await getTherapy();
	},
	mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
  },
	data() {
	  return {
      table_type: 'diagSupervise',
		operateList: [],
		isActiveEditTableHead: false,
		multipleSelectionPres: [],
		activities: [],
		tableHead: [],
		searchTableHead: [
		  //控制搜索
		  {
			name: "order_sn",
			isSearch: false,
		  },
		  {
			name: "created_at",
			isSearch: false,
		  },
		  {
			name: "doctors_name",
			isSearch: false,
		  },
		  {
			name: "supervise_status",
			isSearch: false,
		  },
		  {
			name: "type",
			isSearch: false,
		  },
		],
		tableHeadSortable: [
		  //控制排序
		  "created_at",
		  "expired_at",
		  "next_visit_at",
		  "end_visit_at",
		  "end_order_at",
		],
		info: {},
		diag_id: "",
		pres_id: "",
		currTherapy: {},
		srcList: [],
		from_sources: ["患者场景", "医生场景", "慢病场景"],
		superviseType: [
		  {
			value: "1",
			label: "图文",
		  },
		  {
			value: "2",
			label: "视频",
		  },
		],
		superviseStatusList: [
		  {
			value: "1",
			label: "未上传",
		  },
		  {
			value: "2",
			label: "待上传",
		  },
		  {
			value: "3",
			label: "已上传",
		  },
		],
		supervise_status: [],
		tableData: [],
		dataDrawerTab1: {},
		dataDrawerTab2: {},
		tableDataDrawerTabs2: [],
		searchParams: {
		  orderTime: [],
		},
		listQuery: {
		  page: 1,
		  limit: 20,
		  importance: undefined,
		  title: undefined,
		  type: undefined,
		  sort: "+id",
		},
		multipleSelection: "",
		total: 0,
		listLoading: false,
		drawerVisible: false,
		activeName: "first",
		seen: false,
		seen2: false,
		current: 0,
		current2: 0,
		isPicIdx: "",
		isPicIdx2: "",
		zIndex: 2000,
	  };
	},
	filters: {
	  formatOrderStatus(v) {
		// 已取消，待接诊，接诊中，已结束
		if (v === 1) {
		  return "待接诊";
		} else if (v === 2) {
		  return "接诊中";
		} else if (v === 3) {
		  return "取消";
		} else if (v === 4) {
		  return "待评价";
		} else if (v === 5) {
		  return "已完成";
		}
	  },
	  formatSuperviseStatus(v) {
		// 未上传，待上传，已上传。
		if (v === 1) {
		  return "未上传";
		} else if (v === 2) {
		  return "待上传";
		} else {
		  return "已上传";
		}
	  },
	},
	methods: {
	  handleSelectionChange(val) {
		this.multipleSelection = [];
		this.multipleSelection = val.map((v, k, a) => v.diag_id);
		console.log(val, "val");
		this.multipleSelectionPres = [];
		val.forEach((item) => {
		  this.multipleSelectionPres.push(item);
		});
	  },
	  handleSearch(prop, value) {
		console.log(prop, value, "2312343");
		this.searchTableHead.forEach((item) => {
		  if (item.name == prop) {
			if (value == "") {
			  item.isSearch = false;
			} else {
			  item.isSearch = true;
			}
		  }
		});
		this.getList("restPage");
	  },
	  handleResetSearch() {
		this.searchParams = {
		  is_wx: "",
		  visit_level: "",
		  buy_count: "",
		};
		this.searchTableHead.forEach((item) => {
		  item.isSearch = false;
		});
		this.choiceDateCreateRecord = [];
		this.choiceDateExpire = [];
		this.choiceDateEndVisit = [];
		this.choiceDateEndOrder = [];
		this.choiceDateNextVisit = [];
		this.getList("restPage");
	  },
	  handleEditTableHead() {
		this.isActiveEditTableHead = true;
	  },
	  async _getAdminFieldIndex() {
		// try {
		//   this.listLoading = true;
		//   var params = {
		// 	type: "diagSupervise",
		//   };
		//   const res = await getAdminFieldIndex(params);
		  this.tableHead = this.table_options.diagSupervise;
		//   console.log(this.tableHead);
		// } catch (err) {
		//   //在此处理错误
		// } finally {
		//   this.listLoading = false;
		// }
	  },
	  getList(rest) {
		if (rest == "restPage") {
		  this.listQuery.page = 1;
		}
		this.listLoading = true;
		let params = {
		  ...this.searchParams,
		};
		params.page = this.listQuery.page;
		params.page_size = this.listQuery.limit;
		params.audit = 1;
		getTherapy(params)
		  .then((response) => {
			let data = response;
			if (data.code == 200) {
			  let _data = data.data;
			  this.total = _data.total;
			  this.listQuery.limit = Number(_data.per_page);
			  this.tableData = _data.list;
			}
			this.listLoading = false;
		  })
		  .catch((error) => {
			console.log(error);
			this.listLoading = false;
		  });
	  },
	  checkSelectable(row) {
		return row.supervise_status === 1;
	  },
	  getDetail(row) {
		console.log("row", row);
		this.diag_id = row.diag_id;
		this.pres_id = row.pres_id;
		this.drawerVisible = !this.drawerVisible;
  
		this.listLoading = true;
		let params = {
		  diag_id: row.diag_id,
		};
		getTherapyDetail(params)
		  .then((response) => {
			let data = response;
			console.log(data);
			if (data.code == 200) {
			  this.info = data.data;
			}
			this.listLoading = false;
		  })
		  .catch((error) => {
			console.log(error);
			this.listLoading = false;
		  });
	  },
	  cellStyle(row, column, rowIndex, columnIndex) {
		// 可单独修改 table 字段颜色
		// if (row.column.label === "问诊编号") {
		//   return 'color: #4ebafa'
		// }
	  },
	  handleUpload(row) {
		console.log(row);
		const params = {
		  ids: JSON.stringify([row.diag_id]),
		};
  
		this.$confirm("确认上传问诊监管?", "提示", {
		  confirmButtonText: "确定",
		  cancelButtonText: "取消",
		  type: "warning",
		})
		  .then(() => {
			putTherapyBatchSupervise(params).then((res) => {
			  if (res.code == 200) {
				this.getList();
				this.$message({
				  type: "success",
				  message: "上传成功!",
				});
			  }
			});
		  })
		  .catch(() => {
			this.$message({
			  type: "info",
			  message: "已取消上传",
			});
		  });
	  },
	  handlerBatchUpload() {
		if (this.multipleSelection.length === 0) {
		  return this.$message({
			type: "warning",
			message: "请选择需要上传的问诊监管!",
		  });
		}
		const params = {
		  ids: JSON.stringify(this.multipleSelection),
		};
		this.$confirm("确认批量上传问诊监管?", "提示", {
		  confirmButtonText: "确定",
		  cancelButtonText: "取消",
		  type: "warning",
		})
		  .then(() => {
			putTherapyBatchSupervise(params).then((res) => {
			  if (res.code == 200) {
				this.getList();
				this.$message({
				  type: "success",
				  message: "上传成功!",
				});
			  }
			});
		  })
		  .catch(() => {
			this.$message({
			  type: "info",
			  message: "已取消上传",
			});
		  });
	  },
	  handleClick(tab, event) {
		console.log(tab, event);
	  },
	  handleCloseDrawer() {
		this.dataDrawer = {};
		this.tableDataDrawer = [];
		this.drawerVisible = false;
	  },
	  //关闭弹窗,刷新列表
	  closeDrawer(e) {
		this.drawerVisible = e;
		this.info = {};
	  },
	  enter(index) {
		console.log(index);
		console.log("enter11111");
		this.seen = true;
		this.current = index;
		this.isPicIdx = index;
	  },
	  enter2(index) {
		console.log("enter22222");
		this.seen2 = true;
		this.current2 = index;
		this.isPicIdx2 = index;
	  },
	  leave() {
		console.log("leave");
		this.seen = false;
		this.current = null;
		this.isPicIdx = null;
	  },
	  leave2() {
		console.log("leave");
		this.seen2 = false;
		this.current2 = null;
		this.isPicIdx2 = null;
	  },
	  under(pics, refPic = "pic") {
		console.log(this.$refs.pic);
		this.zIndex = 9999;
		this.$refs[refPic][0].showViewer = true;
	  },
	  under2(pics, refPic = "pic") {
		console.log(this.$refs.pic2);
		this.zIndex = 9999;
		console.log(1111111111111);
		this.$refs[refPic][0].showViewer = true;
	  },
	},
  };
  </script>
  
  <style scoped lang="scss">
  .header {
	display: flex;
	align-items: center;
	height: 26px;
	margin-bottom: 10px;
 
	i {
	  width: 25px;
	  height: 25px;
	  background-color: #2632fb;
	  color: white;
	  line-height: 25px;
	  text-align: center;
	  margin-right: 8px;
	  border-radius: 2px;
	}
  }
  
  /*tabs 样式*/
  ::v-deep.el-tabs {
	padding: 20px;
 
	.el-tabs__item {
	  font-size: 16px;
	}
 
	.el-tabs__item.is-active {
	  font-weight: bolder;
	  font-size: 16px;
	  color: #2362fb;
	}
 
	.el-tabs__active-bar {
	  font-size: 16px;
	  background-color: #2362fb;
	}
  }
  
  /* el-drawer */
  /deep/ .el-tabs__header {
	margin-bottom: 0;
	padding: 20px 0 0 20px;
  }
  
  /deep/ .el-drawer {
	bottom: 0 !important;
  }
  
  /deep/ .el-drawer__header {
	padding: 0;
	margin: 0;
  }
  
  /deep/ .el-drawer.rtl {
	overflow: visible;
	height: 91vh;
	bottom: 0;
	margin-top: 9vh;
	position: absolute;
  }
  
  /deep/ .el-drawer__container ::-webkit-scrollbar {
	display: none;
  }
  
  /*鼠标滑动,详情颜色改变*/
  .is-hover {
	color: #333;
  }
  
  .is-hover:hover {
	color: #4ebafa;
  }
  
  /*栅格文本居中*/
  .relation-container {
	display: flex;
	/*justify-content:center;!*主轴上居中*!*/
	align-items: center;
	/*侧轴上居中*/
  }
  
  .number-align {
	padding-top: 4px;
  }
  
  /*栅格边框*/
  .el-row {
	/*margin-bottom: 20px;*/
	&:last-child {
	  margin-bottom: 0;
	}
  }
  
  .el-col {
	border-radius: 4px;
  }
  
  /*测试排版使用颜色*/
  /*.bg-purple-dark {*/
  /*	background: #99a9bf;*/
  /*}*/
  
  /*.bg-purple {*/
  /*	background: #d3dce6;*/
  /*}*/
  
  /*.bg-purple-light {*/
  /*	background: #e5e9f2;*/
  /*}*/
  
  .grid-content {
	border-radius: 4px;
	min-height: 36px;
  }
  
  .row-bg {
	padding: 10px 0;
	background-color: #f9fafc;
  }
  </style>
